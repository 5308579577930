.header {
    height: 120px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: rgba(255, 255, 255, 0);
    position: fixed;
    top:0;
}
.header__blank {
    height: 121px;
}

.header__menu {
    display: flex;
    flex-direction: row;
}

.header__menu > .header__nav {
    padding:0 20px;
}

.header__nav {
    align-self: center;
}

.header__bg {
    background-color: white;
    border-bottom: 1px solid #EDEEF2;
}



/* logo logic */

.header__logo-introduction {
    transform: translateY(45px) scale(1);
    
}

.header__logo {
    transition: 0.5s;
    transform: scale(0.5);
}

@media (max-width:768px) {
    .header__logo-introduction {
        transform: scale(0.5);
    }
}