.topMargin4 {
    margin-top: 4px;
}

.topMargin8 {
    margin-top: 8px;
}

.topMargin12 {
 margin-top: 12px;   
}
.topMargin16 {
    margin-top: 16px;   
   }
   
.topMargin20 {
    margin-top: 20px;   
}

.topMargin24 {
    margin-top: 24px;   
}

.topMargin32 {
    margin-top: 32px;   
}


.topMargin48 {
    margin-top: 48px;   
}

.topMargin64 {
    margin-top: 64px;
}

.topMargin96 {
    margin-top: 96px;
}

/* BOTTOM */
.bottomMargin4 {
    margin-bottom: 4px;
}

.bottomMargin8 {
    margin-bottom: 8px;
}

.bottomMargin12 {
    margin-bottom: 12px;
}

.bottomMargin24 {
    margin-bottom: 24px;
}

.bottomMargin48 {
    margin-bottom: 48px;   
}

.bottomMargin64 {
    margin-bottom: 64px;
}

.bottomMargin96 {
    margin-bottom: 96px;
}


/* LEFT */

.leftMargin4 {
    margin-left: 4px;   
}

.leftMargin8 {
    margin-left: 8px;   
}

    .leftMargin12 {
    margin-left: 12px;   
    }
   .leftMargin16 {
       margin-left: 16px;   
      }
      
   .leftMargin20 {
       margin-left: 20px;   
   }
   
   .leftMargin24 {
       margin-left: 24px;   
   }
   
   .leftMargin32 {
       margin-left: 32px;   
   }
   
   
   .leftMargin48 {
       margin-left: 48px;   
   }
   
   .leftMargin64 {
       margin-left: 64px;
   }
  
   
   /* RIGHT */



   .rightMargin4 {
    margin-right: 4px;   
}

.rightMargin8 {
    margin-right: 8px;   
}

    .rightMargin12 {
    margin-right: 12px;   
    }
   .rightMargin16 {
       margin-right: 16px;   
      }
      
   .rightMargin20 {
       margin-right: 20px;   
   }
   
   .rightMargin24 {
       margin-right: 24px;   
   }
   
   .rightMargin32 {
       margin-right: 32px;   
   }
   
   
   .rightMargin48 {
       margin-right: 48px;   
   }
   
   .rightMargin64 {
       margin-right: 64px;
   }