.block {
    width: 1024px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 96px;
    margin-bottom: 96px;
}

@media (max-width: 1280px) {
    .block {
        width: 900px;
    }
}

@media (max-width:1024px) {
    .block {
        width: 670px;
    }
}

@media (max-width:768px) {
    .block {
        width: 540px;
    }
}

@media (max-width:680px) {
    .block {
        width: 370px;
    }
}

@media (max-width:480px) {
    .block {
        width: 320px;
    }
}