.carousel {
    display: flex;
    flex-direction: column;
}

.carousel__content {
    width: 100%;
    height: 400px;
    background-color: black;
    border-radius: 16px;
}

.carousel__slider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    height: 20px;


}

.carousel__sliderPoint {
    width: 8px;
    height: 8px;
    margin: 0px 4px;
    background-color: rgba(0, 196, 140, 0.5);
    border-radius: 100px;
}

.carousel__sliderPointIndex {
    width: 12px;
    height: 12px;
    margin: 0px 4px;
    background-color: rgba(0, 196, 140, 1);
    border-radius: 100px;
}



@media (max-width:680px) {
    .carousel__content {
        height: 200px;
    }
}