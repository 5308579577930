.dish-list {
    display: grid;
    /* flex-wrap: wrap;
    flex-direction: row; */
    overflow-y: hidden;
    margin-bottom: 150px;
    grid-template-columns: 307px 307px 307px;
    grid-column-gap:51px;
    overflow: auto;
}

.order-info {
    height: 96px;
    width: 100%;
    position: fixed;
    display: flex;
    bottom: 0;
    border-top: 1px solid #EDEEF2;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.dish-list__category-name {
    grid-column-start: 1;
    grid-column-end: 4;
}


@media (max-width:1280px) {
    .dish-list {
        grid-template-columns: 410px 410px;
    
    }
    .dish-list__category-name {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

@media (max-width:1024px) {
    .dish-list {
        grid-template-columns: 320px 320px;
        grid-column-gap: 30px;
    }
}

@media (max-width:768px) {
    
    .dish-list {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
    
    }
    .dish-list__category-name {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

@media (max-width:680px) {
    .dish-list {
        grid-template-columns: 1fr;
    
    
    
    }
    .dish-list__category-name {
        grid-column-start: 1;
        grid-column-end: 2;
    }
}