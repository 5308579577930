.sButton {
    position: relative;
    padding: 0;
    height: 48px;
    width: 48px;
    border-radius: 100px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 36px;
}

.items-in-cart {
    z-index: 100;
    top:-5px;
    right: -5px;
    position: absolute;
    width: 22px;
    height: 22px;
    background-color: #2B87DC;
    border-radius: 1000px;
    border: 0px;
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
}