.page {
    display: flex;
    flex-direction: row;
    overflow: hidden;
box-sizing: border-box;
    justify-content: space-evenly;
    align-items: center;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}
.reviews__title {
flex-direction: row;
justify-content: space-between;
    display: flex;
}

.page__about {
    max-width: 560px;
display: flex;
flex-direction: column;
align-items: flex-start;

}

.page__options {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.page__swiper {

}

.swiper__button {
    
}

.page__img {
    width: 480px;
    height: 480px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    
}

.page__map {
    width: 100%;
}
.page__map > iframe {
    border-radius: 10px;
}


@media (max-width: 1280px) {
        .page {
            flex-direction: column;
        }
        .page__options {
            margin-top: 48px;
            border-radius: 40px;
        }

        .swiper__button {
            display: none;
        }

        .page__map {
            display: block;
        }

        .page__img {
            display: none;
        }
}

@media (max-width: 480px) {
    .page__about {
        max-width: 320px;
    }
}