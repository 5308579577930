.introduction {
    height: 790px;
    width: 100%;
    background-color: #1A1F27;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    overflow: hidden;
    position: relative;
}

.introduction__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.introduction-bg__circle {
    position: absolute;
    background-color: #78AB70;
    border-radius: 100%;
    filter: blur(100px);
}

.introduction__animation {
    position: relative;
    width: 460px;
    height: 100%;
}

.canvas__elem5 {
    display: none;
    transform: translate(-20%, 10%);
}





.introduction__animation > img {
    position: absolute;

}

.canvas__elem1 {
 top: 40px;
 transition: 1.7s;
 /* animation-timing-function: ; */
}

.canvas__end1 {
top: 800px;
transform: rotate(-100deg);
}

.canvas__elem2 {
    top:300px;
    left: -150px;
    transition: 1s;
}

.canvas__end2 {
    top: 800px;
    transform: rotate(30deg);
}

.canvas__elem3 {
    top:20px;
    left: 50px;
    transition: 1.5s;
    z-index: 2;
}

.canvas__end3 {
    top:800px;
    transform: rotate(60deg);
}

.canvas__elem4 {
    top: 350px;
    left: 300px;
    transition: 1s;
    z-index: 1;
}

.canvas__end4 {
    top:800px;
    transform: rotate(-30deg);
}




.bgCircleOne {
    margin-left: -100px;
    margin-top: 480px;
    height: 150px;
    width: 150px;
    animation-name: circleAnimTwo;
    animation-duration: 12s;
    animation-delay: 3s;
    animation-iteration-count: infinite;
}

.bgCircleTwo {
    height: 150px;
    width: 150px;
    margin-left: 50%;
    top: 5%;
    animation-name: circleAnimOne;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

.bgCircleThree {
    height: 200px;
    width: 200px;
    right: -100px;
    margin-top: 400px;
    animation-name: circleAnimTwo;
    animation-duration: 9s;
    animation-iteration-count: infinite;
}



/* Bg */

/* Circle Animation */

@keyframes circleAnimOne {
    0% {
    transform: scale(1);
    transform: translateY(0px);
    }
    50% {
        transform: scale(1.2);
        transform: translateY(100px);
    }
    100% {
        transform: scale(1);
        transform: translateY(0px);
    }
}

@keyframes circleAnimTwo {
    0% {
    transform: scale(1);
    transform: translateX(0px);
    }
    50% {
        transform: scale(1.2);
        transform: translateX(100px);
    }
    100% {
        transform: scale(1);
        transform: translateX(0px);
    }
}



@media (max-width: 1280px) {
    .introduction__animation > img {
        display: none;
        
    }
    .introduction__animation > .canvas__elem5 {
        display: block;
    }

    .introduction__animation {
        width: 200px;
    }
    }

    @media (max-width:1024px) {
     .canvas__elem5 {
        display: none !important;
     }   
     .introduction__animation {
        width: 0px;
     }
    }



    @media (max-width:768px) {
        .header__nav {
            display: none;
        }
        .introduction__text {
            width: 440px;
        }
        
    }


    @media (max-width: 480px) {
        .introduction__text {
            width: 330px;
        }

        .montHeader {
            font-size: 48px;
        }
    }