.review-form-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9;
  
}



.review-form {
    position: fixed;
    left: 50%;
    top: 50px;
    transform: translateX(-50%) translateY(25%);
    z-index: 1000;
    padding: 40px;
min-width: 280px;

background-color: white;
border-radius: 12px;
display: flex;
flex-direction: column;
}

.review-form__textarea {
    resize: none;
}

.review-form__input {
    padding: 16px;
    border-radius: 7px;
    border: 1px solid black;
}

.review-form__button {
    padding: 16px;
    background-color: #00c48c;
    border-radius: 7px;
    border: 0px;

}