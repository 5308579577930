.news-detail__img {
    width: 100%;
    border-radius: 10px;
    background-color: black;
    height: 400px;
}


@media (max-width: 680px) {
    .news-detail__img {
        height: 200px;
    }
}